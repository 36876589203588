import React, { Component } from "react";

import golf from "../../images/activity/WynnGolfCourse.jpg";

export class Golf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: "850px",
      imageMarginLeft: "0",
      isMobile: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("isMobile")) {
      this.setState({ imageWidth: "360px" }, () => {
        console.log(this.state.imageWidth);
      });
      this.setState({ imageMarginLeft: "0" }, () => {
        console.log(this.state.imageWidth);
      });
    }
  }

  render() {
    return (
      <>
        <div>
          <div style={{ width: "40%" }}>
            <img
              src={golf}
              style={{
                maxWidth: this.state.imageWidth,
                paddingTop: "20px",
                marginLeft: this.state.imageMarginLeft,
              }}
              alt=""
            ></img>
          </div>
          <br />
          <div style={{textAlign: "center", width: '90%'}}>
            <p>
              You are scheduled to play in the John Hughes Memorial Golf
              Tournament. Wynn Golf Club is a 6,722-yard, par-70 championship length course built to accomodate and
              challange golfers of all skill levels.
            </p>
        
            <p>
              Start Time: 1:00 pm 
              </p>
              <p style={{marginTop: "-13px"}}>Pairings will be announced at the course.
            </p>
          </div>
        </div>
      </>
    );
  }
}
