import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import ExchangeLogo from "../images/logos/Collaboration-Exchange-Logo-A.png";

import "../css/exchange.css";
export class Exchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exchangeInfo: {
        data: {},
      },
      attendExchange: JSON.parse(localStorage.getItem("attendeeInfo")).data.attendExchange,
      attendeeEmail: "",
      isMobile: "",
      imageWidth: '',
      imageMarginTop: '',
      textWidth: '50%',
      textPaddingLeft: '10px'
    };
  }

  componentDidMount() {
    this.setState(
      { attendeeEmail: localStorage.getItem("attendeeEmail") },
      () => {
        this.getAttendeeInfo();
      }
    );

    if(localStorage.getItem("isMobile") == "true")
    {
      this.setState({imageWidth: '130px'}, () =>
      {   
        console.log(this.state.imageWidth);
      }
      );
      this.setState({imageMarginTop: '-81px'}, () =>
      {    
        console.log(this.state.imageMarginTop);
      }
      );
      this.setState({textWidth: '70%'}, () =>
      {    
        console.log(this.state.textWidth);
      }
      );
      this.setState({textPaddingLeft: '76px'}, () =>
      {    
        console.log(this.state.textPaddingLeft);
      }
      );     
    }
  
  }
  getAttendeeInfo = async () => {
    await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_ATTENDEE_DATA_URL +
        this.state.attendeeEmail,
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          crossorigin: "use-credentials",
          credentials: "include",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((respData) => {
        this.setState({ exchangeInfo: respData }, () => {
          console.log(
            "attendee info:" + JSON.stringify(this.state.exchangeInfo)
          );
        });

        this.setState({attendExchange: respData.data.attendExchange}, () =>
        {
          console.log(this.state.attendExchange);
        })
      })
      .catch((err) => {
        //alert('error fetching data');
      });
  };

  render() {
    /*    const obj =
      sessionStorage.AttendeeInfo != null
        ? JSON.parse(sessionStorage.AttendeeInfo)
        : ""; */
    const {imageWidth, imageMarginTop, textWidth, textPaddingLeft} = this.state;

    function getExchangeTime(key) {
      var result = key
        .toString()
        .substring(3)
        .replace(/\D/g, "");
      var minBegin = result.slice(-2);
      var minEnd = parseInt(minBegin) + 15;

      if (result.length === 3) {
        result = "0" + result;
      }
      var exchangeTime = "";
      if (minEnd == 60) {
        minEnd = "00";
        var hour = parseInt(result.slice(0, 2)) + 1;
        exchangeTime =
          result.slice(0, 2) +
          ":" +
          minBegin +
          " - " +
          hour +
          ":" +
          minEnd +
          " am";
      } else {
        exchangeTime =
          result.slice(0, 2) +
          ":" +
          minBegin +
          " - " +
          result.slice(0, 2) +
          ":" +
          minEnd +
          " am";
      }
      return exchangeTime;
    }

    const obj = this.state.exchangeInfo;

    var contents = Object.keys(obj.data).map((key) => {
      if (
        key.indexOf("_") !== -1 &&
        obj.data[key] !== null &&
        obj.data[key] !== "" &&
        !obj.data[key].includes("No Scheduled Appointments")
      ) {
        return (
          <tr>
            <td>{getExchangeTime(key)}</td>
            <td>{obj.data[key]}</td>
          </tr>
        );
      }
    });

    

    return (
      <>
        <div className="App">
          <div className="container outer">

            {
              this.state.attendExchange.toLowerCase() == "yes" ? 

              (<div>
                <div
                  style={{
                    paddingRight: "5px",
                    width: "20%",
                    display: "inline-block",
                    borderRight: "1px solid gray",
                  }}
                >
                  <img alt="" src={ExchangeLogo} width={imageWidth} style={{marginTop: imageMarginTop}}></img>
                </div>
                <div
                  style={{
                    width: textWidth,
                    display: "inline-block",
                    paddingLeft: textPaddingLeft
                  }}
                >
                  <p>
                    You are scheduled to attend the Division + Supplier
                    Collaboration Exchange.
                  </p>
                  <p>Details of who you will be meeting with are listed below.</p>
                </div>
                <hr style={{ marginTop: "-1px", marginLeft: "-9px" }} />
             

              <Table striped bordered hover size="sm" style={{textAlign: "center"}}>
                <th>Exchange Time</th>
                <th>Company</th>
                <tbody>{contents}</tbody>
              </Table>
              </div>
                  
              ): (
                <div
                className="container"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  minHeight: "630px",
                }}>
                  You do not have any scheduled Division + Supplier Collaboration Exchange Appointments</div>)
  }
          </div>
        </div>
      </>
    );
  }
}
