import '../css/Login.css';
import React from 'react';

import Login from './login';


function Logout(props) {
     
   

   
    localStorage.removeItem("attendeeEmail");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("attendeeInfo");
    localStorage.removeItem("attendeeName");
    localStorage.removeItem("isMobile");
    

   
    return (
        <div><Login /></div>
    )

    
}
export default Logout