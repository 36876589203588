import React, { Component } from "react";
import "../css/resources.css";
import Suppliers from "../images/ListOfSuppliers.jpg"

export class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfUrl: '',
      pdfFiles: {
        "data": []  
      }
    }
  }
  
async componentDidMount() {
    await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_PDF ,
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((respData) => {
        this.setState({ pdfFiles: respData}, () => {
          console.log("attendee info:" + this.state.pdfFiles);
        });
      })
      .catch((err) => {
        console.log('error fetching data');
      });
  }
  render() {

    const {pdfUrl, currentLocation} = this.state;
    const resourceStyle = {
      backgroundColor: "greeen",
      padding: "10px",
    };

    const obj = this.state.pdfFiles;
    var links = null;
    if (obj && obj.data) {
        links =  Object.keys(obj.data).map((val, index) => (
          <tr key={index}>
            <td>
              <p>
                <span className="bullet">&bull;</span>
                <a href={obj.data[val].url} target="_blank">{obj.data[val].name}</a>
              </p>
            </td>
          </tr>
        ));
    }
    

    return (
      <div className="App">
        <div
          className="container"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignContent: "center",
            minHeight: "630px",
          }}
        >
          <h1 id="tabelLabel">Resources</h1>
          <div class="resourceStyle">
            <h5><u>Conference Wifi Details</u></h5>
            <p>&bull;  Network: SSID: KB Home Supplier Conference</p>
            <p>&bull;  Password: 2023KBHSC</p>
            <br />
            <div>
            <p><b>Powerpoint PDFs</b></p>
              <table>
                <tbody>{links}</tbody>
              </table>
            </div>
             <div>
             <p><b>Podcast links:</b></p>
                <ul>
                  <li>
                    <p>&bull; <a href="https://podcasts.apple.com/us/podcast/strong-conversations/id1714499165" target="_blank">Simpson Strong-Tie (Apple)</a></p>
                  </li>
                  <li>
                    <p>&bull; <a href="https://open.spotify.com/episode/3E4XrTk59Cpdbtn0JVc9Pi?si=hzyTzgFHQUWZAGtHiNC8zQ&nd=1" target="_blank">Simpson Strong-Tie (Spotify)</a></p>
                  </li>
                </ul>
              
             </div>
            <p>&bull;  <a href="/hotelmap" target="_blank">Property Map</a></p>

            <div>
          <p>&bull;  <a  href={Suppliers} target="_blank">List of Suppliers</a></p>
          </div>

          </div>
               
        </div>
      </div>
    );
  }
}
