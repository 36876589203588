import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavItem from "react-bootstrap/NavItem";
import NavDropdown from "react-bootstrap/NavDropdown";

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import KBHomeLogo from "../images/logos/KB_LogoSmall.png";
import "../css/NavMenu.css";
import Footer from "./footer";

export class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      loggedInUser: "",
      isMobile: "",
      collapseOnSelect: "auto",
      marginBottom: "1%",
      attendExchange: ""
    };
  }

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") != null) {
      this.setState({ isLoggedIn: localStorage.getItem("isLoggedIn") }, () => {
        console.log(this.state.isLoggedIn);
      });
    }
    if (localStorage.getItem("attendeeName") != null) {
      this.setState(
        { loggedInUser: localStorage.getItem("attendeeName") },
        () => {
          console.log(this.state.loggedInUser);
        }
      );
    }
    if (localStorage.getItem("isMobile") != null) {
      this.setState(
        { isMobile: localStorage.getItem("isMobile") },
        () => {
          console.log(this.state.isMobile);
        }
      );
    }
    if (localStorage.getItem("attendeeInfo") != null) {
      this.setState(
        { attendExchange: JSON.parse(localStorage.getItem("attendeeInfo")).data.attendExchange },
        () => {
          console.log(this.state.attendExchange);
        }
      );
    }
  }

  onClick() {
    localStorage.removeItem("attendeeName");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("attendeeEmail");
    localStorage.removeItem("attendeeInfo");
    localStorage.removeItem("isMobile");
    window.location.reload();
  }

  collapseComponent() {
    this.setState({ collapseOnSelect: "0px" }, () => {});
  }
  render() {
    const { isLoggedIn, loggedInUser, isMobile, attendExchange } = this.state;
    return (
      <>
        {isLoggedIn ? (
          <div>
            <div style={{ marginBottom: this.state.marginBottom }}>
              <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                  <Navbar.Brand href="" style={{ marginLeft: isMobile ? '0%' : '7%' }}>
                    <a href="/home"><img
                      src={KBHomeLogo}
                      style={{ maxWidth: "50px" }}
                      alt=""
                    ></img></a>
                    <a href="/home" className="app-name" >&nbsp;&nbsp;{new Date().getFullYear()}&nbsp;Supplier
                    Conference</a>
                  </Navbar.Brand>

                  <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ marginLeft: "85%", marginTop: "-14%" }}
                  />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav
                      className="me-auto"
                      style={{ height: "collapseOnSelect" }}
                    >
                      <NavItem collapseOnSelect>
                        <NavLink
                          to="/home"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Home
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/schedule"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Agenda
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/hotel-reservation"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Hotel Reservation
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/reception-dinner"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Meals
                        </NavLink>
                      </NavItem>

                    {/*   { attendExchange == "Yes" &&
                      <NavItem>
                        <NavLink
                          to="/exchange"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Exchange
                        </NavLink>
                      </NavItem>
                      } */}
                      <NavItem>
                        <NavLink
                          to="/activity"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Activity
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/give-me-two"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Give Me 2
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/audience-polling"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Polling
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/photos"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Photos
                        </NavLink>
                      </NavItem>
                  {/*     <NavItem>
                        <NavLink
                          to="/directory"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Directory&nbsp;
                        </NavLink>
                      </NavItem> */}

                      <Nav>
                        <NavDropdown
                          title={loggedInUser}                     
                        >                                         
                          {/*   !isMobile &&
                            ( */}
                              <div>
                           <NavDropdown.Item
                            href="/contact"
                            onClick={this.logOut}
                          >
                            Contacts
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            href="/resources"
                            onClick={this.logOut}
                          >
                            Resources
                          </NavDropdown.Item>
                          </div>
                           {/*  )}  */}
                        <NavDropdown.Item
                            href="/logout"
                            onClick={this.logOut}>
                            Logout                           
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>

                      {/*  <NavItem>
                          <NavLink className="nav-link">
                            {loggedInUser}&nbsp;&nbsp;
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            to="/login"
                            className="nav-link"
                            onClick={this.onClick}>
                            Sign out
                          </NavLink>
                        </NavItem>  */}
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
       {/*       { isMobile && 
            <div className="footer-mobile">
              <Footer />
            </div>
  } 
 */}
          </div>
        ) : (
          <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Container>
                <Navbar.Brand href="">
                  <img
                    src={KBHomeLogo}
                    style={{ maxWidth: "50px" }}
                    alt=""
                  ></img>
                  &nbsp;&nbsp;{new Date().getFullYear()}&nbsp;Supplier
                  Conference
                </Navbar.Brand>
              </Container>
            </Navbar>
          </div>
        )}
      </>
    );
  }
}
