import React, { Component } from "react";
import LaCave from "../../images/restaurantHotel/LaCave.jpg";
import Steakhouse from "../../images/restaurantHotel/SWRestaurant.jpg";
import Jardin from "../../images/restaurantHotel/TheThriller.PNG";
import "../../css/receptiondinner.css";

export class Tuesday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: '600px'
    };
  }
  componentDidMount() {
    if(localStorage.getItem("isMobile") == "true"){
      this.setState({width: '350px'}, () =>
      { console.log(this.state.width);}
      );
    };
  }

  render() {
    const {width} = this.state;
  
      return (
        <>
          {this.props.tuesdayNightDinner === "Jardin" && (
            <div className="border">
                 <div>
                <img src={Jardin} alt="" style={{ width: width}}></img>
              </div>
              <p>
                Costumes are welcome however are not required. If you do dress up, please dress in a business appropriate 
                Halloween costume.
              </p>

              <p className="half-height">Location: Jardin</p>
              <p className="half-height">From: 6:30 - 9:30 PM</p>

            </div>
          )}

        </>
      );
    
  }
}
