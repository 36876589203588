import * as React from "react"
import Table from "react-bootstrap/Table";
import "../../css/Schedule.css";


const DayOne = () => {
const isMobile = localStorage.getItem("isMobile");
const sevenFontSize = "10px";

    return (
        <>
     
            <div style={{marginTop: "-16px"}}>
            <Table  
            size="sm"
            bordered           
            >
            <tbody>
              <tr style={{height: "60px"}}>
                 <td  style={{borderRight: "none",  borderBottom: "none" }}  colSpan={2}><h4>Tuesday, October 31st, 2023</h4></td>
                 <td style={{borderLeft: "none",  borderBottom: "none" }}></td>            
              </tr>      
             
              <tr>
                <td className="td1"><p>11:00 am –<span className="line-break">12:30 pm</span></p>                                
                </td>
                <td className="td2">
                <p className="topic">Registration and Lunch</p>     
                      
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Chopin</a></p>
                </td>    
                <td className="td3">
                    </td>                       
              </tr>
              <tr>
                <td className="td1"><p>12:30 pm –<span className="line-break"></span> 1:15 pm</p>              
                </td>
                <td className="td2">
                <p className="topic">Building Connections. Delivering on Customer Obsession</p>
                <ul className="agendas">
                    <li className="reduce-top">
                    &bull; Connecting operations to our vision
                    </li>
                    <li>
                    &bull; Customer touchpoints
                    </li>
                    <li>
                    &bull; Employee team excellence
                    </li>
                    <li>
                    &bull; Supplier and trade partner commitment
                    </li>
                    <li>
                    &bull; Conference challenges
                    </li>
                </ul>
              
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">           
                    <p>Jeff Mezger</p>                 
                </td>
              </tr>
              <tr>
                <td className="td1"><p>1:15 pm –<span className="line-break"></span> 1:30 pm</p>                 
                </td>
                <td className="td2">
                <p className="topic">Welcome</p>
                <ul className="agendas">
                <li className="reduce-top">
                    &bull; Conference Logistics
                    </li>
                    <li>
                    &bull; Overview & Themes
                    </li>                 
                </ul>               
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3">
                    </td> 
              </tr>
              <tr>
                <td className="td1"><p>1:30 pm –<span className="line-break"></span> 2:00 pm</p>                     
                </td>
                <td className="td2">
                <p className="topic">The Extra Degree</p>
                <ul className="agendas">
                <li className="reduce-top">
                    &bull; Delivering on Customer Obsession
                    </li>
                    <li>
                    &bull; Partnerships
                    </li>
                    <li>
                    &bull; Powerful Business Model
                    </li>
                    <li>
                    &bull; Consistency
                    </li>
                    <li>
                    &bull; Expectations
                    </li>
                </ul>
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">             
                <p>Rob McGibney</p>        
                </td>
              </tr>
         
              <tr>
                <td className="td1"><p>2:00 pm –<span className="line-break"></span> 2:30 pm</p>
                                    
                </td>
                <td className="td2">
                <p className="topic">It's All in the Numbers</p>
                <ul className="agendas">
                <li className="reduce-top">
                    &bull; Health of the Business
                    </li>
                    <li>&bull; Macro-Economic Perspective</li>
                    <li>&bull; Measuring Success</li> 
                    <li>&bull; Expectations</li>                  
                </ul>
           
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">
                <p>Jeff Kaminski</p>                     
                </td>
              </tr>
              
              <tr>
                <td className="td1"><p>2:30 pm –<span className="line-break"></span> 2:45 pm</p>              
                </td>
                <td className="td2">
                <p className="topic">Break</p>
                </td>            
                <td className="td3 speaker">            
                </td>
              </tr> 
              <tr>
                <td className="td1"><p>2:45 pm –<span className="line-break"></span> 4:15 pm</p></td>
                <td className="td2">
                <p className="topic">Taking Care of Business</p>
                <ul className="agendas">
                <li className="reduce-top">&bull; Fixing the Rocks in the River</li>
                <li>&bull; Sourcing Evolution in Key Categories</li>  
                <li>&bull; Relentless Customer Focus</li>
                <li>&bull; Expectations</li>                     
                </ul>

                <p className="topic">Advancing Customer Obsession</p>
                <ul className="agendas">
                <li className="reduce-top">
                    &bull; Simplification
                    </li>
                    <li>&bull; Personalization</li>
                    <li>&bull; AI and The Digital Experience</li>                                         
                </ul>
                           
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">
                  <p className="italicized reduce-bottom" >Melissa McGhee </p>
                  <p>Suppliers and Guest</p> 
                  <br />
                  <br />
                  <p className="italicized reduce-bottom" >Gena Kirk</p>                 
                  <p>Suppliers and Guest</p>                      
                </td>
              </tr>

              <tr>
                <td className="td1"><p>4:15 pm –<span className="line-break"></span> 5:00 pm</p>                 
                </td>
                <td className="td2">
                <p className="topic">The Customer Experience: Live Chat with the Leano and Santos Families</p>
                <br />
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>    
                <td className="td3 speaker">
                <p className="reduce-bottom">Roxane Mussleman </p>
                <p className="reduce-bottom">Gena Kirk</p>
                <p className="reduce-bottom">Dan Bridleman</p>
                <p className="reduce-bottom">Rob McGibney</p> 
                  </td>                    
              </tr>
              <tr>
                <td className="td1"><p>5:00 pm –<span className="line-break"></span> 5:15 pm</p>                 
                </td>
                <td className="td2">
                <p className="topic">Day One Wrap-up</p>
                <br />
                <br />
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>    
                <td className="td3 speaker">
                <p className="reduce-bottom">Rob McGibney </p>
                <p className="reduce-bottom">Dan Bridleman</p>
                <p className="reduce-bottom">Gena Kirk</p>
                <p className="reduce-bottom">Melissa McGhee</p>
                                 
                </td>                  
              </tr>


              <tr>
                <td className="td1"><p>6:30 pm –<span className="line-break"></span> 9:30 pm</p>              
                </td>
                <td className="td2">
                <p className="topic"><a href="/reception-dinner" target="_blank">Reception & Dinner</a></p>
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Jardin</a></p>
                </td>            
                <td className="td3 speaker">            
                </td>
              </tr>             
     
            </tbody>
          </Table>
            </div>        
            </>      
    )
}
export default DayOne
