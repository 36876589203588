import React, { Component } from "react";
import { Dunebuggy } from "./activity/dunebuggy";
import { Golf } from "./activity/golf";
import { Spa } from "./activity/spa";
import { Skydiving } from "./activity/skydiving";
import { Digthis } from "./activity/digthis";


import { Loading } from "./activity/loading";

import HotelMap from "./hotelmap";

export class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marginLeft: '22%',
      wednesdayActivityInfo: {},
      wednesdayActivityType: "",
      spaType: "",
      spaTime: "",
      spaLocation: "",
      golfClubRentals: "",
      golfBus: "",
    };
  }

  componentDidMount() {
    this.getAttendeeInfo();
 
    if(localStorage.getItem("isMobile") == "true"){
        this.setState({marginLeft: '0px'}, () =>
        {console.log(this.state.marginLeft)}
        );
      };

  }
  getAttendeeInfo = async () => {
    await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_ATTENDEE_DATA_URL +
        localStorage.getItem("attendeeEmail"),
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          crossorigin: "use-credentials",
          credentials: "include",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((respData) => {
        this.setState({ wednesdayActivityInfo: respData }, () => {

          this.setState({ wednesdayActivityType: this.state.wednesdayActivityInfo.data.wednesdayActivity }, () => {
            console.log('wednesdayActivityType:'+(this.state.wednesdayActivityType));
            });
            this.setState({ spaType: this.state.wednesdayActivityInfo.data.spaType }, () => {
              console.log('spaType:'+(this.state.spaType));
              });
              this.setState({ spaTime: this.state.wednesdayActivityInfo.data.spaTime }, () => {
                console.log('spaTime:'+(this.state.spaTime));
                });
                this.setState({ spaLocation: this.state.wednesdayActivityInfo.data.spaLocation }, () => {
                  console.log('spaLocation:'+(this.state.spaLocation));
                  });
                this.setState({ golfClubRentals: this.state.wednesdayActivityInfo.data.golfClubRentals }, () => {
                  console.log('golfClubRental:'+(this.state.golfClubRentals));
                  });
                  this.setState({ golfBus: this.state.wednesdayActivityInfo.data.golfBus }, () => {
                    console.log('golfBus:'+(this.state.golfBus));
                    });     
                      
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {


    const {
      wednesdayActivityType,
      spaType,
      spaTime,
      spaLocation,
      golfClubRentals,
      golfBus,
      marginLeft
    } = this.state;
    return (
      <>
        <div className="App">
          <div
            className="container"
            style={{
              marginTop: "10px",
              marginLeft: marginLeft,
              marginRight: "auto",
              minHeight: "1200px",
            }}
          >
            <div className="col-lg-8 col-xs-12 col-centered">
              {  wednesdayActivityType !== "None" ?              
              (                 
                (() => {
                  if (wednesdayActivityType.toLowerCase() == "dunebuggytour"){
                    return <div> <Dunebuggy /></div>
                  }
                  if (wednesdayActivityType.toLowerCase() == "spa" ){
                    return <div> <Spa spaType={spaType} spaTime={spaTime} spaLocation={spaLocation}/></div>
                  }
                  if (wednesdayActivityType.toLowerCase() == "digthis"){
                    return <div> <Digthis /></div>
                  }
                  if (wednesdayActivityType.toLowerCase() == "golf" ){
                
                    return <div> <Golf /></div>
                  }
                  else
                  {
                    return <div><Loading /></div>
                  }
                                 
                })()
            
              ) : (
                <div>
                  <p>You have elected to have the afternoon free.</p>
                  <b />
                  <p>If you have any need any assistance please contact us. </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
