import React, { Component } from "react";

import { Monday } from "./receptiondinner/monday";
import { Tuesday } from "./receptiondinner/tuesday";
import { Wednesday } from "./receptiondinner/wednesday";

import Collapsible from "./collapsible";
//import Collapsible from 'react-collapsible';

export class ReceptionDinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMonday: false,
      openTuesday: false,
      openWenesday: false,
      attendeeEmail: "",
      mondayNightDinner: "",
      tuesdayNightDinner: "",
      wednesdayNightDinner: "",
      tableNumber: "",

      currentDate: "",
    };
  }

  componentDidMount() {
    this.openTabs();
    this.getAttendeeInfo();
  }

  openTabs() {
    let currentDate = new Date().toLocaleString().split(",")[0];
    //alert(currentDate);
    currentDate = "10/30/2023";

    if (currentDate == "10/30/2023") {
      this.setState({ openMonday: true }, () => {
        console.log(this.state.openMonday);
      });
    } else if (currentDate == "10/31/2023") {
      this.setState({ openTuesday: true }, () => {
        console.log(this.state.openTuesday);
      });
    } else if (currentDate == "11/01/2023") {
      this.setState({ openWednesday: true }, () => {
        console.log(this.state.openWednesday);
      });
    }
  }

  getAttendeeInfo = async () => {
    await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_ATTENDEE_DATA_URL +
        localStorage.getItem("attendeeEmail"),
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          crossorigin: "use-credentials",
          credentials: "include",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((respData) => {
        this.setState(
          { mondayNightDinner: respData.data.mondayNightDinner },
          () => {
            this.setState(
              { tuesdayNightDinner: respData.data.tuesdayNight },
              () => {
                this.setState(
                  { wednesdayNightDinner: respData.data.wednesdayDinner },
                  () => {
                    this.setState(
                      { tableNumber: respData.data.awardsDinnerTable },
                      () => {
                        console.log("Dinner Table" + this.state.tableNumber);
                      }
                    );
                  }
                );
              }
            );
          }
        );
      })
      .catch((err) => {
        alert("error fetching data");
      });
  };

  render() {
    const {
      mondayNightDinner,
      tuesdayNightDinner,
      wednesdayNightDinner,
      tableNumber,
      openMonday,
      openTuesday,
      openWednesday,
    } = this.state;

    return (
      <>
        <div className="App">
          <div id="container" style={{ height: "1500px", textAlign: "center" }}>
            <div
              className="container"
              style={{
                alignContent: "center",
                minHeight: "630px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
  
                {mondayNightDinner != "No" && (
                  <Collapsible
                    label={"Monday, Oct 30th"}
                    defaultOpen={openMonday}
                  >
                    <Monday mondayNightDinner={mondayNightDinner} />
                  </Collapsible>
                )}

                <br />
               
                <Collapsible
                  label="Tuesday, Oct 31st"
                  defaultOpen={openTuesday}
                >
                  <Tuesday tuesdayNightDinner={tuesdayNightDinner} />
                </Collapsible>
     
                <br />
                {wednesdayNightDinner != "No" && (
                <Collapsible
                  attend={"NO"}
                  label="Wednesday, Nov 1st"
                  defaultOpen={openWednesday}
                >
                  <Wednesday
                    wednesdayNightDinner={wednesdayNightDinner}
                    tableNumber={tableNumber != null ? tableNumber : "TBD"}
                  />
                </Collapsible>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
