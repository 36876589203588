import * as React from "react"
import "../../css/Schedule.css";
import Table from "react-bootstrap/Table";


const DayTwo = () => {

  const isMobile = localStorage.getItem("isMobile");
  const sevenFontSize = "10px";

    return (
        <>
    
        <div>

        <div style={{marginTop: "-16px"}}>
            <Table  size="sm" bordered>
            <tbody>
            <tr style={{height: "60px"}}>
                 <td  style={{borderRight: "none",  borderBottom: "none" }}  colSpan={3}><h4>Wednesday, November 1st, 2023</h4></td>
                 <td style={{borderLeft: "none",  borderBottom: "none" }}></td>               
              </tr>
              <tr>
                <td className="td1"><p>7:00 am –<span className="line-break"></span> 8:00 am</p>                   
                </td>
                <td className="td2">
                <p className="topic">Breakfast</p>               
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize : "14px" }}>Location: <a href="/hotelmap" target="_blank">Chopin</a></p>
                </td>  
                <td className="td3"></td>             
           
              </tr> 

              <tr>
                <td className="td1"><p>8:00 am –<span className="line-break"></span> 8:15 am</p>
                                 
                </td>
                <td className="td2">
                <p className="topic">Welcome to Day Two</p>
                
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>    
                <td className="td3">
                <p>Dan Bridleman</p>
                <p className="reduce-bottom">Rob McGibney</p>
                <p className="reduce-bottom">Steve Ruffner</p>
                </td>
                        
               
              </tr>

              <tr>
                <td className="td1"><p>8:15 am –<span className="line-break"></span> 8:45 am</p>              
                </td>
                <td className="td2">
                <p className="topic">Sustaining the Dream</p>
                <ul className="agendas">
                    <li className="reduce-top">
                    &bull; Sustainable Value
                    </li>
                    <li>
                    &bull; Power Connections
                    </li>
                    <li>
                    &bull; Building a Sustainable Future
                    </li>
                
                </ul>
              
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">           
                    <p className="italicized reduce-bottom" >Jacob Atalla</p>   
                    <p>Arial Chen</p>               
                </td>
              </tr>


              <tr>
                <td className="td1"><p>8:45 am –<span className="line-break"></span> 9:05 am</p>              
                </td>
                <td className="td2">
                <p className="topic">Customer Obsession: Real Life Success Stories</p>
                <ul className="agendas">
                    <li className="reduce-top">
                    &bull; Lessons Learned
                    </li>
                    <li>
                    &bull; Expectations
                    </li>
                    
                </ul>
              
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">           
                    <p>Brian Kunec</p>                
                </td>
              </tr>

              <tr>
                <td className="td1"><p>9:05 am –<span className="line-break"></span> 9:15 am</p>              
                </td>
                <td className="td2">
                <p className="topic">Cyber security/phishing/data & AI</p>
                <ul className="agendas">
                    <li className="reduce-top">
                    &bull; "Business Email Compromise" Scenario
                    </li>
                    <li>
                    &bull; Emphasize the criticality of sharing information, timely notifications, 
                    connecting the right people within our organizations to respond to incidents
                    </li>                
                </ul>
              
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">           
                    <p>Greg Moore</p>                
                </td>
              </tr>

              <tr>
                <td className="td1"><p>9:15 am –<span className="line-break"></span> 9:30 am</p>                   
                </td>
                <td className="td2">
                <p className="topic">Break</p>   
                </td>                          
              </tr> 

              <tr>
                <td className="td1"><p>9:30 am –<span className="line-break"></span> 10:45 am</p>              
                </td>
                <td className="td2">
                <p className="topic">Innovator's Arena: Give Me 2 Shark Tank</p>
                <ul className="agendas">
                    <li className="reduce-top">
                    &bull; Dynamic Give Me 2 Process
                    </li>
                    <li>
                    &bull; Vetted live with Panel and Audience feedback
                    </li>                
                </ul>
                <br />
                <br />
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">           
                    <p className="reduce-bottom" >Dan Bridleman</p>
                    <p className="reduce-bottom" >Rob McGibney</p>     
                    <p className=" reduce-bottom" >Steve Ruffner</p>     
                    <p className="reduce-bottom" >Melissa McGhee</p>     
                    <p className="reduce-bottom" >Gena Kirk</p>     
                    <p className="reduce-bottom" >Jacob Atalla</p>
                    <p className="reduce-bottom" >Suppliers</p>                          
                </td>
              </tr>
              <tr>
                <td className="td1"><p>10:45 am –<span className="line-break"></span> 11:00 am</p>                  
                </td>
                <td className="td2">
                <p className="topic">Day Two Wrap-up</p>
                <br />
                <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>
                </td>            
                <td className="td3 speaker">
                <p className="italicized reduce-bottom">Rob McGibney</p>
                <p>Dan Bridleman</p>                          
                </td>
              </tr>

                 <tr>
                <td className="td1"><p>Per Schedule</p>                   
                </td>
                <td className="td2">
                <p className="topic">Afternoon Activities</p>   
                </td>                          
              </tr> 

              <tr>
                <td className="td1">
                <p>6:30 pm –<span className="line-break"></span> 10:00 pm</p>
                </td>
                <td className="td2">
                <p className="topic">Awards Gala</p>              
                    <p>Strategic Supplier Awards Dinner</p>   
                    <p className="italicized reduce-bottom" style={{fontSize: isMobile ? sevenFontSize: "14px" }}>Location: <a href="/hotelmap" target="_blank">Encore Ballroom</a></p>       
                </td>            
                <td className="td3 speaker"> 
                <p>Rob McGibney</p>
                <p className="reduce-top">Dan Bridleman</p>             
                </td>
              </tr>
            </tbody>
          </Table>
            </div>
        </div>
            </>
    )
}
export default DayTwo
