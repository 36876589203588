import React, { Component } from "react";

import "../css/Schedule.css";

import { ScheduleTabs } from "./tabs/ScheduleTabs.tsx";
import TabOne from "./tabs/ScheduleTabOne.tsx";
import TabTwo from "./tabs/ScheduleTabTwo.tsx";



export class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isMobile: false,
      value: "tab1",
      setValue: "",
      attendeeInfo: {},
      fontSize: '14px'
    };
  }
  componentDidMount() {
    if(localStorage.getItem("isMobile") == "true"){
      this.setState({fontSize: '12px'}, () =>
      {console.log(this.state.fontSize)}
      );
    };

  }

  render() {
    if (localStorage.getItem("isLoggedIn") == null) {
      window.location = "/login";
    }
    const tabs = [
      {
        label: "Day One",
        Component: TabOne,
      },
      {
        label: "Day Two",
        Component: TabTwo,
      },
    ];
    return (
      <div className="App">
        <div id="container" style={{ height: "1200px" }}>
          <div class="container">
            
            <div style={{fontSize: this.state.fontSize}}>
              <ScheduleTabs tabs={tabs} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
